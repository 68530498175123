.languages{
    width: 30px;
    height: 20px;
    background-size: cover;
    user-select: none;
    cursor: pointer;
}

.english{
    background-image: url('https://media.infrasolva.com/website/language/english.jpg');
}

.greatbritain{
    background-image: url('https://media.infrasolva.com/website/language/greatbritain.jpg');
}

.quebecois{
    background-image: url('https://media.infrasolva.com/website/language/quebecois.jpg');
}

.french{
    background-image: url('https://media.infrasolva.com/website/language/french.jpg');
}

.language-element{
    padding-top: 5px;
    height: 25px;
    display: flex;
    user-select: none;
    cursor: pointer;
}

.language-element > p{
    margin-top: -1px;
    margin-left: 10px;
    font-family: 'sourcesans3';
}

.language-menu{
    position: relative;
}

.language-menu > div:first-of-type{
    margin-top: -40px;
    width: 60px;
    height: 60px;
    position: absolute;
    cursor: pointer;
}

.language-menu > div:first-of-type > div{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.language-menu > div:last-of-type{
    position: relative;
    margin-top: 20px;
    margin-left: -6px;
}

.language-menu > div:last-of-type > div{
    background-color: #fff;
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
    max-height: 0px;
    padding: 0 20px;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out, padding 0.2s ease-in-out;
    position: absolute;
    margin-top: 20px;
}

.language-menu:hover > div:last-of-type > div{
    border-top: 1px solid #eee;
    max-height: 100px;
    padding: 10px 20px;
}

@media screen and (max-width: 768px){
    .language-menu:hover > div:last-of-type > div{
        border: none;
    }

    .language-menu > div:last-of-type > div{
        margin-top: 14px;
    }
}