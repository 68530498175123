.footer{
    width: calc(100% - 40px);
    margin: 0px;
    padding: 20px;
    background-color: #111;
    color: #fff;
    font-family: 'sourcesans3';
    font-size: 15px;
    position: relative;
}

.footer > div:not(:nth-of-type(2)){
    width: 60%;
    min-width: 675px;
    margin: auto;
    display: flex;
}

.footer > div:first-of-type{
    justify-content: space-between;
}

.footer-separator{
    margin-left: -20px;
    margin-top: 10px;
    display: block;
    width: calc(100% + 40px);
    height: 1px;
    background-color: #383838;
}

.footer > div:last-of-type{
    height: 15px;
    padding: 5px 0px;
}

.footer > div:last-of-type > p{
    margin: 8px 0px;
    margin-right: 20px;
}

.footer > div:last-of-type > div{
    margin: 0px 10px;
}

.footer-sitemap{
    display: flex;
}

.footer-logo-n-socials{
    margin-top: 20px;
}

.footer-logo > div{
    width: 100px;
    height: 50px;
    background-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo_dark_color.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    image-rendering: optimizeQuality;
}

.footer-logo > p{
    font-size: 30px;
    margin: 0px;
    text-align: left;
}

.footer-title-list{
    text-align: left;
}

.footer-title-list:first-of-type{
    margin-right: 200px;
}

.footer-title-list > p{
    font-size: 18px;
}

.footer-link{
    margin: 0px;
    color: #aaa;
    cursor: pointer;
}

.footer-link:hover{
    color: #fff;
}

.footer-link > p{
    margin: 8px 0px;
}

.footer-socials{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

.footer-socials > a{
    color: #aaa;
    font-size: 25px;
}

.footer-socials > a:hover{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-socials > a > i{
    display: block;
    width: 38px;
    text-align: left;
}

@media screen and (max-width: 768px){
    .footer > div:not(:nth-of-type(2)){
        flex-direction: column;
        min-width: 0px;
        width: 100%;
    }

    .footer-sitemap{
        flex-direction: column;
    }

    .footer-title-list > div{
        width: 300px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: clamp(50px, 50px, 100px);
        list-style: none;
    }

    .footer > div:last-of-type{
        height: fit-content;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 0px;
        margin-bottom: -15px;
    }
}

@media screen and (max-width: 334px){
    .footer-title-list > p{
        width: 140px;
    }

    .footer-title-list > div{
        width: fit-content;
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
        column-gap: 0px;
    }
}