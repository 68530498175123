.main-nav{
    position: fixed;
    top: 0;
    margin: 0px;
    width: 100%;
    height: 60px;
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid #eee;
}

.main-nav-not-visible{
    display: none;
}

.main-nav > div:first-of-type{
    max-width: 1300px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.main-nav > div > div > ul{
    padding: 0;
    padding-right: 5%;
    margin: 0px 15%;
    width: calc(100% - 35%);
    height: 100%;
    display: flex;
    list-style: none;
    justify-content: flex-end;
    position: relative;
}

.main-nav > div > div > ul > li{
    user-select: none;
    cursor: pointer;
    display: block;
    height: 60px;
    padding: 0px 5%;
    justify-content: center;
    font-family: 'sourcesans3';
    transition: background-color 0.15s ease-in-out;
    position: relative;
}

.main-nav > div > div > ul > li > p{
    margin-top: 20px;
}

.active-page{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -10px;
    width: 50px;
    height: 2px;
    background-color: #333;
}

.active-page-product{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 50px;
    width: 100px;
    height: 2px;
    background-color: #333;
}

.main-nav > div > div > ul > li:hover{
    background-color: #eee;
}

.navbar-logo{
    position: absolute;
    z-index: 9999;
    top: 10px;
    left: 7.5%;
}

.burger-btn{
    z-index: 999;
    display: none;
    top: 11px;
    right: 12.5px;
    font-size: 25px;
    position: absolute;
    transition: transform 0.2s ease-out;
}

.burger-btn-active{
    transform: rotate(-90deg);
}

.navbar-user{
    position: absolute;
    top: 4px;
    right: 3.75%;
}

.navbar-languages{
    position: absolute;
    top: 20px;
    right: calc(3.75% + 120px);
    flex-wrap: nowrap;
    z-index: 99999;
}

.products-nav{
    cursor: default;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw;
    height: 0px;
    background-color: #eee;
    transition: height 0.3s ease-out;
    overflow: hidden;
}

.products-nav > div{
    margin: auto;
    max-width: 900px;
    display: flex;
}

.products-main-nav{
    cursor: default !important;
}

.products-main-nav:hover > .products-nav{
    height: 70px;
}

.product-element-of-nav{
    padding: 10px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
    position: relative;
}

.product-element-of-nav:hover{
    background-color: #fafafa;
}

.product-element-of-nav > div:first-of-type{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-size: cover;
}

.eagle-work{
    background-image: url("https://media.infrasolva.com/website/product/eagle-work.png");
}

.products-nav-closed{
    height: 0px !important;
}

@media screen and (max-width: 768px){
    .main-nav{
        height: 50px;
    }

    .main-nav > div{
        height: 50px;
    }

    .main-nav > div > div:last-of-type{
        position: absolute;
        width: 100%;
    }

    .main-nav > div > div > ul{
        flex-direction: column;
        justify-content: start;
        overflow: hidden;
        margin: 0;
        margin-top: 50px;
        width: 100%;
        height: fit-content;
        background-color: #fff;
        border-bottom: 1px solid #eee;
    }

    .nav-elements{
        max-height: 160px;
        transition: max-height 0.3s ease-in-out;
    }

    .nav-closed{
        max-height: 0px;
    }

    .main-nav > div > div > ul > li{
        height: 40px;
        border: none;
    }

    .main-nav > div > div > ul > li > p{
        margin-top: 10px;
    }

    .burger-btn{
        display: block;
    }

    .navbar-logo{
        top: 5px;
        left: 10px;
    }

    .navbar-user{
        top: 2px;
        right: 50px;
    }

    .navbar-languages{
        top: 15px;
        right: 160px;
        z-index: 99999;
    }

    .products-nav{
        margin-top: 150px;
        flex-direction: column;
    }

    .products-main-nav:hover > .products-nav{
        height: 50px;
    }

    .product-element-of-nav{
        padding: 0px;
        margin: auto;
        position: relative;
    }

    .product-element-of-nav > div:first-of-type{
        width: 30px;
        height: 30px;
    }

    .product-element-of-nav:hover{
        background-color: transparent;
    }

    .active-page{
        margin-top: -15px;
    }

    .active-page-product{
        margin-top: 35px;
    }
}