.main-logo{
    width: 80px;
    height: 40px;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
}

.main-logo > div{
    display: flex;
    margin-top: -35%;
    width: 120%;
    height: 240%;
}

.main-logo > div > div:first-of-type{
    width: 33.5%;
    height: 80%;
    margin-top: 10%;
    background-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo.svg");
    background-size: cover;
}

.main-logo > div > div:last-of-type{
    width: 50%;
    height: 100%;
    mask-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo.svg");
    mask-size: 170%;
    mask-position: right;
}

.main-logo > div > div:last-of-type > div{
    width: 100%;
    height: 100%;
    position: relative;
}

.main-logo > div > div:last-of-type > div > div{
    width: 100%;
    height: 100%;
    position: absolute;
}


/* STANDARD */

.standard-logo > div:first-of-type{
    background-color: rgb(255, 150, 0);
}

.standard-logo > div:last-of-type{
    background-image: linear-gradient(to right,rgb(255,0,0,1) 0%,rgb(255, 0, 0, 0) 70%);
}

.main-logo:hover > div > div:last-of-type > .standard-logo > div:first-of-type{
    animation: colorAnim 1s;
}

@keyframes colorAnim{
0% {background-color: rgb(255, 150, 0);}
20% {background-color: rgb(255, 207, 74);}
100% {background-color: rgb(255, 150, 0);}
}


/* CHRISTMAS */

.christmas-logo, .christmas-logo > div:first-of-type{
    background-image: url("https://media.infrasolva.com/website/logo/christmas/back.jpg");
    background-size: 100%;
    background-position: center;
}
.main-logo:hover > div > div:last-of-type > .christmas-logo > div:first-of-type{
    animation: rainbowRotation 5s infinite linear;
}

.christmas-logo > div:last-of-type{
    background-image: url("https://media.infrasolva.com/website/logo/christmas/front.png");
    background-size: 80%;
    background-position: center;
    transition: filter 0.3s ease-in-out;
}

.main-logo:hover > div > div:last-of-type > .christmas-logo > div:last-of-type{
    filter: brightness(1.2);
}

@keyframes rainbowRotation {
0% {transform: rotate(0deg);}
100% {transform: rotate(360deg);}
}


/* NEW YEAR */

.new-year-logo, .new-year-logo > div:first-of-type{
    background-image: url("https://media.infrasolva.com/website/logo/new-year/back.jpg");
    background-size: 300%;
    background-position: center;
}
.main-logo:hover > div > div:last-of-type > .new-year-logo > div:first-of-type{
    animation: glitterRotation 5s infinite linear;
}

.new-year-logo > div:last-of-type{
    background-image: url("https://media.infrasolva.com/website/logo/new-year/front.png");
    background-size: 200%;
    background-position: center;
    transition: filter 0.3s ease-in
}

.main-logo:hover > div > div:last-of-type > .new-year-logo > div:last-of-type{
    filter: brightness(1.2);
    opacity: 0.7;
}

@keyframes glitterRotation {
0% {transform: rotate(0deg);}
100% {transform: rotate(360deg);}
}