.unavailable-version-txt {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-family: 'sourcesans3';
    font-size: 14px;
}

.unavailable-page {
    width: 100%;
    height: 100%;
}

.unavailable-page > div {
    margin-top: 200px;
}

.unavailable-page > div > div:last-of-type{
    font-size: 20px;
}

.unavailable-logo {
    margin: auto;
    width: 600px;
    height: 300px;
}

.unavailable-animation {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-image: url("https://media.infrasolva.com/website/logo/animation/crane-body.png");
    background-size: 80%;
    background-position: top right;
    background-repeat: no-repeat;
    position: relative;
    animation: animation-opacity 2s;
}

.crane-trolley {
    position: absolute;
    margin-top: 72px;
    margin-left: 150px;
    width: 44px;
    height: 215px;
    overflow: hidden;
    animation: trolley 12s infinite;
}

.crane-trolley > div:first-of-type {
    width: 100%;
    height: 20px;
    background-image: url("https://media.infrasolva.com/website/logo/animation/crane-trolley.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
}

.crane-trolley > div:last-of-type {
    width: 2px;
    height: 0px;
    background-color: #ff8c19;
    margin-left: 20px;
    animation: trolley-cable 12s infinite;
}

.logo-parts {
    position: absolute;
    margin-left: 150px;
    margin-top: 58px;
    width: 300px;
    height: 230px;
    overflow: hidden;
    display: flex;
    animation: logo-opacity 12s infinite;
}

.logo-i-part {
    margin-top: 135px;
    width: 35px;
    height: 300px;
    background-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo_light_color.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    animation: logo-i 12s infinite;
}

.logo-s-part {
    margin-top: 135px;
    width: 90px;
    height: 300px;
    overflow: hidden;
    animation: logo-s 12s infinite;
}

.logo-s-part > div {
    margin-left: -35px;
    width: 125px;
    height: 100%;
    background-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo_light_color.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}

.logo-shape-part {
    margin-top: 135px;
    width: 180px;
    height: 300px;
    background-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo_light_color.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    animation: logo-shape 12s infinite;
}

@keyframes animation-opacity {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes trolley {
    0% {margin-left: 150px;}
    20% {margin-left: 150px;}
    30% {margin-left: 211px;}
    50% {margin-left: 211px;}
    60% {margin-left: 340px;}
    80% {margin-left: 340px;}
}

@keyframes trolley-cable {
    0% {height: 0px;}
    5% {height: 195px;}
    5.4% {height: 200px;}
    15% {height: 75px;}
    20% {height: 50px;}
    30% {height: 50px;}
    35% {height: 195px;}
    35.4% {height: 200px;}
    45% {height: 75px;}
    50% {height: 50px;}
    60% {height: 50px;}
    65% {height: 195px;}
    65.4% {height: 200px;}
    75% {height: 75px;}
    81% {height: 0px;}
}

@keyframes logo-i {
    0% {margin-top: 150px;}
    5% {margin-top: 150px;}
    15% {margin-top: 0px;}
    99% {margin-top: 0px;}
    99.3% {margin-top: 150px;}
}

@keyframes logo-s {
    0% {margin-top: 150px;}
    35% {margin-top: 150px;}
    45% {margin-top: 0px;}
    99% {margin-top: 0px;}
    99.3% {margin-top: 150px;}
}

@keyframes logo-shape {
    0% {margin-top: 150px;}
    65% {margin-top: 150px;}
    75% {margin-top: 0px;}
    96% {margin-top: 0px;}
    97% {margin-top: 150px;}
}

@keyframes logo-opacity {
    0% {opacity: 1;}
    85% {opacity: 1;}
    95% {opacity: 0;}
    99.9% {opacity: 0;}
    100% {opacity: 1;}
}

@media screen and (max-width: 768px){

    .unavailable-page > div {
        margin-top: 250px;
    }

    .unavailable-logo {
        width: 200px;
        height: 150px;
        background-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo_light_color.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .unavailable-animation {
        display: none;
    }

    .unavailable-page > div > div:last-of-type{
        font-size: 16px;
    }
}