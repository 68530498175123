.not-found-page {
    position: absolute;
    margin: 0;
    width: 100%;
    background-image: linear-gradient(to bottom, #cccccc, #d9d9d9, #e5e5e5, #f2f2f2, #ffffff);
}

.not-found-art {
    user-select: none;
    margin-top: -20px;
    margin-left: 30px;
    width: fit-content;
    height: 420px;
    display: flex;
    opacity: 30%;
    position: absolute;
}

.not-found-art > div:first-of-type{
    height: 420px;
    overflow: hidden;
    z-index: -1;
}

.not-found-art > div:first-of-type > p {
    margin: 0;
    font-family: 'sourcesans3';
    font-weight: bold;
    font-size: 400px;
    z-index: -1;
}

.not-found-art > div:last-of-type {
    margin-top: 75px;
    width: 415px;
    height: 415px;
    background-image: url("https://media.infrasolva.com/website/logo/infrasolva_logo_shape_only.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.not-found-section {
    margin-top: 450px;
}

.not-found-description {
    margin-left: 100px;
    text-align: left;
    font-size: 25px;
    font-family: 'sourcesans3';
}

.not-found-btn {
    user-select: none;
    cursor: pointer;
    margin-top: 0px;
    margin-left: 100px;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    color: white;
    font-family: 'sourcesans3';
    font-size: 18px;
    overflow: hidden;
    background: linear-gradient(to right,rgb(255,0,0,1) 0%,rgb(255, 150, 0) 70%);
}

.not-found-btn > div{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    background-color: #000f;
    transition: background-color 0.2s ease-out;
}

.not-found-btn:hover > div {
    background-color: #0000;
}

.not-found-btn > div > p {
    margin: 0px;
    padding: 0px;
}

@media screen and (max-width: 1080px){

    .not-found-art{
        margin-left: 15px;
    }

    .not-found-art > div:first-of-type > p {
        font-size: 300px;
    }

    .not-found-art > div:last-of-type {
        margin-top: 57.5px;
        width: 310px;
        height: 310px;
    }

    .not-found-section {
        margin-top: 350px;
    }

    .not-found-description {
        margin-left: 80px;
        font-size: 22px;
    }

    .not-found-btn {
        margin-left: 80px;
    }
}

@media screen and (max-width: 768px){

    .not-found-art {
        margin-left: calc(50vw - 151.5px);
        height: 150px;
    }

    .not-found-art > div:first-of-type{
        height: 150px;
    }

    .not-found-art > div:first-of-type > p {
        font-size: 120px;
    }

    .not-found-art > div:last-of-type {
        margin-top: 23px;
        width: 125px;
        height: 125px;
    }

    .not-found-section {
        margin-top: 200px;
    }

    .not-found-description {
        margin-left: 0px;
        text-align: center;
        width: 100vw;
        font-size: 20px;
    }

    .not-found-btn {
        margin: auto;
        margin-top: 50px;
        font-size: 16px;
    }
}