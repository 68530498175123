@import "./home/home.css";
@import "./products/index.css";
@import "./company/company.css";
@import "./news/news.css";
@import "./contact/contact.css";
@import "./notFound/notFound.css";
@import "./unavailable/unavailable.css";

.page{
    margin: 0px;
    margin-top: 60px;
    position: relative;
    z-index: 0;
    width: 100%;
    padding: 0px;
    flex-grow: 1;
}

@media screen and (max-width: 768px){
    .page{
        margin-top: 50px;
    }
}