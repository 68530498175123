.home-page{
    width: 100%;
    box-sizing: border-box;
}

.home-page-top-banner{
    width: 100%;
    height: calc(100vh - 60px - 250px);
    background: linear-gradient(to right, red,gold);
}

.home-page-values-section{
    margin: auto;
    width: 60%;
    min-width: 767px;
}

.home-page-values-section > p{
    margin: 20px;
    margin-bottom: -25px;
    font-family: 'sourcesans3';
    font-size: 28px;
}

.home-page-values-section > div{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.home-page-value-element{
    width: calc(33% - 70px);
    min-width: 150px;
    margin: 25px;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 10px;
    padding-bottom: 0px;
    border-radius: 5px;
}
.home-page-value-element > i{
    font-size: 30px;
}

.home-page-value-element > p:first-of-type{
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'sourcesans3';
}

.home-page-value-element > p:last-of-type{
    margin-top: 0px;
    font-family: 'sourcesans3';
    text-align: center;
}

@media screen and (max-width: 768px){
    .home-page-values-section{
        width: 100%;
        min-width: 0px;
    }

    .home-page-values-section > div{
        flex-direction: column;
        justify-content: center;
    }

    .home-page-value-element{
        margin-left: auto;
        margin-right: auto;
        width: 350px;
        max-width: 90vw;
    }

    .home-page-value-element:not(:last-of-type){
        margin-bottom: -20px;
    }

    .home-page-values-section > p{
        font-size: 25px;
    }
}