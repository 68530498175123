.user-menu{
    width: 45px;
    height: 45px;
    font-size: 35px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
}

.user-menu > i{
    position: absolute;
    display: block;
    margin: 5px;
    margin-top: 2px;
    padding: 0;
    width: 35px;
    height: 35px;
}