.cook-banner{
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;
    width: calc(100% - 120px);
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 0px 15px #0002;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    margin: 0px;
    padding: 10px 60px;
    color: #222;
    font-family: 'sourcesans3';
}

.cook-banner-closed{
    display: none;
}

.cook-banner a{
    color: #222;
}

.cook-banner > div:first-of-type{
    align-content: center;
    padding-right: 40px;
}

.cook-banner-btn{
    margin: 10px 0px;
    width: 130px;
    height: 32px;
    background: linear-gradient(to right,rgb(255,0,0,1) 0%,rgb(255, 150, 0) 70%);
    border-radius: 7px;
    cursor: pointer;
    user-select: none;
    display: flow-root;
    color: #fff;
    font-size: 14px;
    overflow: hidden;
}

.cook-banner-btn > div{
    width: 100%;
    height: 100%;
    align-content: center;
    background: #222;
    transition: background 0.2s ease, border 0.2s ease, color 0.2s ease;
}

.cook-banner-btn > div > p{
    text-align: center;
    margin: 0;
}

.cook-banner-btn:nth-of-type(2) > div{
    background-color: #ddd;
    color: #222;
}

.cook-banner-btn:last-of-type > div{
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: white;
    border: 2px solid #222;
    color: #111;
    border-radius: inherit;
}

.cook-banner-btn > div:hover{
    background: transparent;
}

.cook-banner-btn:nth-of-type(2) > div:hover{
    color: #fff;
}

.cook-banner-btn:last-of-type > div:hover{
    border: 2px solid transparent;
    color: #fff;
}

.cook-customize-modal{
    position: relative;
    max-width: 550px;
    min-height: 600px;
    max-height: 600px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    font-family: 'sourcesans3';
}

.cook-customize-modal > div:first-of-type{
    overflow-y: auto;
    min-height: 500px;
    max-height: 500px;
    padding: 20px 25px;
}

.cook-customize-modal > div:nth-last-of-type(2){
    margin-left: -25px;
    width: 600px;
    height: 1px;
    background: #eee;
}

.cook-customize-modal > div > p{
    font-size: 15px;
    padding-right: 40px;
}

.cook-customize-modal > div > p > a{
    color: #222;
}

.cook-customize-modal-header{
    display: flex;
    justify-content: space-between;
    height: 30px;
}

.cook-customize-modal-header > p{
    font-size: 21px;
    margin: 0;
}

.cook-customize-modal-header > i{
    display: block;
    width: 30px;
    height: 25px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    padding-top: 2px;
}

.cook-customize-modal-btns{
    position: absolute;
    margin: 0px 25px;
    bottom: 5px;
    width: calc(100% - 50px);
    height: 50px;
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.cook-customize-modal-btns > div{
    width: calc(33.33% - 12px);
}

.cook-preference-element{
    border: 2px solid #eee;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 10px;
    padding-bottom: 20px;
}

.cook-preference-element > p{
    margin: 0;
    font-size: 16px;
}

.cook-preference-element > div{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.cook-preference-element > div > p{
    margin: 0;
    font-size: 14px;
    color: #888;
}

.cook-toggle{
    cursor: pointer;
    overflow: hidden;
    margin-left: 20px;
    min-width: 50px;
    width: 50px;
    height: 25px;
    border-radius: 12.5px;
    background: linear-gradient(to right,rgb(255,0,0,1) 0%,rgb(255, 150, 0) 70%);
}

.cook-toggle-readonly{
    opacity: 0.5;
    cursor: not-allowed;
}

.cook-toggle > div{
    padding: 0px 3px;
    padding-top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 3px);
    background: #222;
    transition: background 0.25s ease, padding-left 0.25s ease;
}

.cook-toggle-activated > div{
    padding-left: 28px;
    background: transparent;
}

.cook-toggle > div > div{
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #fff;
}

@media screen and (max-width: 768px){

    .cook-banner{
        flex-direction: column;
    }

    .cook-banner > div:first-of-type{
        padding-right: 0px;
    }

    .cook-banner > div:last-of-type{
        display: flex;
        justify-content: space-between;
    }

    .cook-banner-btn{
        width: 47%;
    }

    .cook-customize-modal > div > p{
        padding-right: 0px;
    }

    .cook-customize-modal{
        min-width: calc(90%);
        max-width: calc(90%);
        max-height: calc(75vh - 100px);
        min-height: 0px;
        padding-bottom: 140px;
    }

    .cook-customize-modal > div:first-of-type{
        max-height: calc(75vh - 190px);
        min-height: 0px;
    }

    .cook-customize-modal > div:nth-last-of-type(2){
        margin-bottom: 5px;
        margin-left: -25px;
        width: calc(90vw + 30px);
    }

    .cook-customize-modal-btns{
        flex-direction: column-reverse;
        height: 115px;
        bottom: 15px;
    }

    .cook-customize-modal-btns > div{
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 524px){

    .cook-banner{
        width: calc(100% - 60px);
        padding: 10px 30px;
    }

    .cook-banner > div:last-of-type{
        flex-direction: column;
    }

    .cook-banner-btn{
        width: 100%;
        margin: 5px 0px;
    }

    .cook-customize-modal-btns > div{
        margin: 0;
    }
}